export const LIES_VARS = [
  {
    name: "ton-grimmoireproductions",
    term: "Ton",
    description: "The Ton was the high society in the United Kingdom during the Regency era."
  },
  {
    name: "marquess-grimmoireproductions",
    term: "Marquess",
    description: "Pronounced “MAR-kwess” not “mar-KEY”"
  }
]